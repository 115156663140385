import VueRouter from 'vue-router'
import StartPage from './views/Start.vue'

import PlayPage from './views/Play.vue'
import MainPage from './views/Main.vue'
import DecadesPage from './views/Decades.vue'
import DecadePage from './views/Decade.vue'
import VideoPage from './views/Video.vue'
import TrubilinPage from './views/Trubilin.vue'
import FacultiesPage from './views/Faculties.vue'
import FacultyPage from './views/Faculty.vue'
import DepartmentsPage from './views/Departments.vue'
import DepartmentPage from './views/Department.vue'
import BooksPage from './views/Books.vue'
import NotFoundPage from './views/NotFound.vue'


export default new VueRouter({
    routes: [
        {
            path: '',
            component: MainPage
        },
        {
            path: '/start',
            component: StartPage,
        },
        {
            path: '/play',
            component: PlayPage,
        },
        {
            path: '/decades',
            component: DecadesPage,
        },
        {
            path: '/decades/:code',
            name: 'decades.detail',
            component: DecadePage,
            meta: {
                parent: "/decades",
            },
        },
        {
            path: '/video',
            component: VideoPage
        },
        {
            path: '/trubilin',
            component: TrubilinPage
        },
        {
            path: '/faculties',
            component: FacultiesPage
        },
        {
            path: '/faculties/:code',
            name: 'faculties.detail',
            component: FacultyPage,
            meta: {
                parent: "/faculties",
            },
        },
        {
            path: '/departments',
            component: DepartmentsPage
        },
        {
            path: '/departments/:code',
            name: 'departments.detail',
            component: DepartmentPage,
            meta: {
                parent: "/departments",
            },
        },
        {
            path: '/books',
            component: BooksPage
        },
        {
            path: '*',
            name: 'NotFound',
            component: NotFoundPage
        }
    ],
    mode: 'history'
})
