<template>
  <main class="pageStart">
    <router-link class="a-buttonStart" to="/" @click.native="play()">
      <img class="a-buttonStart-logo" src="../assets/img/logo100.svg" />
    </router-link>
  </main>
</template>

<script>
import {mapMutations} from "vuex";
import axios from "axios";

export default {
  name: 'StartPage',
  metaInfo: {
    title: 'Старт',
  },
  methods: {
    ...mapMutations (["setNotStartPage"]),
    play(){
      axios
          .get('https://museum.a-dev.xyz/api/play')
          .then(response => (this.links = response.data))
          .catch(() => this.$router.push({name: "NotFound"}));
    }
  },
  beforeMount() {
    this.setNotStartPage(false)
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.pageStart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10% 0;
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
  width: 100%;
  background: url(../assets/img/bgStart3.jpg) center center no-repeat;
  background-size: cover;

  .a-buttonStart {
    background: $white;
    padding: 60px;
    border-radius: 60px;
    box-shadow: 4px 3px 27px 14px rgba(0, 0, 0, 0.4);

    @include mediaMaxW($xs) {
      padding: 46px;
      border-radius: 46px;
    }

    &:hover, &:active {
      box-shadow: 4px 3px 27px 0px rgba(0, 0, 0, 0.2);
    }

    &-logo {
      height: 160px;
      width: auto;

      @include mediaMaxW($xs) {
        height: 70px;
      }
    }
  }
}
</style>
