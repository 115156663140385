<template>
  <div class="m-container">
    <CardDecade
        :key="link.link"
        v-for="link in links"
          :title="link.title"
          :pic="link.preview"
          :link="link.code"
    />
  </div>
</template>

<script>
import CardDecade from "../components/CardDecade";
import {mapMutations} from "vuex";
import {Decades} from "@/models/models";
import axios from "axios";

export default {
  name: "DecadesPage",
  metaInfo: {
    title: 'Десятилетия',
  },
  components: {
    CardDecade
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Decades
  },
  beforeMount() {
    this.setTitle("Десятилетия")
    this.setPageClass("pageDecades")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null
    };
  },
  mounted() {
    axios
        .get('/api/decades')
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageMain {
  .m-container {
    @include mediaMinW($md) {
      max-width: 640px;
    }
  }
  @include mediaMaxW($xs) {
    justify-content: flex-start;
  }
}
</style>
