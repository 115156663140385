<template>
  <div class="m-container">
    <div class="m-text">
      <CardVideo
          :key="link.link"
          v-for="link in links"
            :title="link.title"
            :poster="link.picture"
            :url="link.url"
            :youtube="link.youtube"/>
    </div>
  </div>

</template>

<script>
import CardVideo from "../components/CardVideo";
import {mapMutations} from "vuex";
import {Video} from "@/models/models";
import axios from "axios";

export default {
  name: "VideoPage",
  metaInfo: {
    title: 'Видео',
  },
  components: {
    CardVideo
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Video
  },
  beforeMount() {
    this.setTitle("Видеогалерея")
    this.setPageClass("pageVideo")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null
    };
  },
  mounted() {
    axios
        .get('/api/videos')
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageVideo{
  .m-text{
    .m-video {
      margin-top: 8px!important;
    }
  }
  .m-container {
    @include mediaMinW($md) {
      max-width: 640px;
    }
  }
}
</style>
