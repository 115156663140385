<template>
  <div class="m-container">
    <CardBook
        :key="link.link"
        v-for="link in links"
          :cover="link.preview"
          :title="link.title"
          :authors="link.authors"
          :description="link.description"
          :qr="link.qr"
          :link="link.link"
    />
  </div>
</template>

<script>
import CardBook from "../components/CardBook";
import {mapMutations} from "vuex";
import {Decades} from "@/models/models";
import axios from "axios";

export default {
  name: "BooksPage",
  components: {
    CardBook
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Decades
  },
  beforeMount() {
    this.setTitle("Книги о КубГАУ")
    this.setPageClass("pageBooks")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null,
    };
  },
  mounted() {
    axios
        .get('/api/books')
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageBooks {
  .m-container {
    @include mediaMinW($md) {
      max-width: 800px;
    }
  }
}
</style>
