<template>
  <a
      class="a-btnSquare"
      v-bind:class="{ green: green, goToTop: goToTop }"
      :href="link"
      :title="title"
      :data-src="action"
      :style="type"
      @click="$emit('click')"
  >
    <img v-if="icon" :src="require('../assets/img/'+icon+'.svg')">
    <span v-if="text">{{text}}</span>
  </a>
</template>

<script>
export default {
  name: 'btnSquare',
  props: {
    green: Boolean,
    goToTop: Boolean,
    icon: String,
    link: String,
    title: String,
    text: String,
    action: String,
    type: String
  }
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.a-btnSquare {
  display: flex;
  background: $black5;
  border-radius: 10px;
  border: 1px solid $black20;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #22222299;
  font-weight: 700;

  &:hover, &:active {
    border: 1px solid $accentGreen;
  }

  &.green{
    border: 1px solid $accentGreen;
    background: $lightGreen;
    &:hover {
      box-shadow: $shadowGreen;
    }
  }

  img{
    height: 24px;
    width: 24px;
    color: #22222288;

    @include mediaMinW($md) {
      height: 14px;
      width: 14px;
    }
    @include mediaMaxW($xs) {
      height: 12px;
      width: 12px;
    }
  }

  img + span {
    margin-left: 8px;
  }

  &.goToTop {
    display: flex;
    transition: none;
    position: absolute;
    bottom: 64px;
    right: 24px;
    z-index: 10;

    @include mediaMinW($md) {
      bottom: 48px;
    }
    @include mediaMaxW($xs) {
      display: none!important;
    }
  }

  @include mediaMaxW($xs) {
    &[data-src="#link"],
    &[data-src="#instagram"],{
      display: none;
    }
  }
}
</style>
