<template>
  <div class="m-container">
    <div class="m-text">

      <img v-if="links.picture" v-img:group :src=links.picture :alt=links.name>

      <div v-if="links.content" class="m-text" v-html="links.content"></div>

      <div v-if="links.photos" class="m-text">
        <img
            :key="item.item"
            v-for="item in links.photos"
            v-img:group :src=item :alt=links.name>
      </div>

      <h2 v-if="links.departments" class="h2">Кафедры факультета:</h2>
      <BlockAccordion
          v-if="links.departments"
          :list="links.departments"/>

    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import BlockAccordion from "@/components/BlockAccordion";
import axios from "axios";
import {Faculty} from "@/models/models";

export default {
  name: 'FacultyPage',
  metaInfo: {
    title: 'Факультет',
  },
  components: {
    BlockAccordion
  },
  methods: {
    ...mapMutations(["setTitle", "setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Faculty
  },
  beforeMount() {
    this.setTitle("")
    this.setPageClass("pageFaculty")
    this.setBtnHome(true)
    this.setBtnBack(true)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null,
    };
  },
  mounted() {
    axios
        .get('/api/faculty/'+this.$route.params.code)
        .then(response => (this.links = response.data, this.setTitle(this.links.name)))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">

</style>
