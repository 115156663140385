<template>
    <div class="m-decade">
      <div class="m-decadeYears">
        <div>
          {{title.substr(0,4)}}
        </div>
        <div>
          {{title.substr(5,4)}}
        </div>
      </div>
      <div class="a-decadeVertical">
      </div>
      <div class="a-decadePin">
      </div>
      <router-link class="m-decadeCard"
         :style="{backgroundImage: 'url('+pic+')'}"
         :to="'decades/' + link"
      >
        <span class="a-decadeCardGradient"></span>
      </router-link>
    </div>
</template>

<script>
export default {
  name: 'CardDecade',
  props: {
    title: String,
    pic: String,
    link: String,
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.m-decade {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .m-decadeYears {
    font-size: 30px;
    font-weight: 700;

    @include mediaMaxW($xs) {
      font-size: 20px;
    }

    div{
      width: 111px;

      @include mediaMaxW($xs) {
        width: 68px;
      }

      &:first-child {
        border-bottom: 1px solid $accentGreen;
      }
      &:last-child {
        border-top: 1px solid $accentGreen;
      }
    }
  }

  .a-decadeVertical {
    height: 250px;
    width: 3px;
    display: block;
    background: $accentGreen;

    @include mediaMaxW($xs) {
      height: 176px;
    }
  }

  .a-decadePin {
    width: 24px;
    height: 24px;
    background: $accentGreen;
    background: linear-gradient(300deg, rgba(122,213,40,1) 0%, rgba(119,199,37,1) 33%, rgba(59,176,30,1) 66%, #3aaf1e 100%);
    position: absolute;
    left: 99px;
    transform: rotate(45deg);

    @include mediaMaxW($xs) {
      left: 57px;
    }
  }

  .m-decadeCard {
    display: block;
    height: 200px;
    width: 100%;
    margin: 24px 0 24px 50px;
    background-size: cover;
    background-position: center center;
    position: relative;

    @include mediaMaxW($xs) {
      margin: 12px 0 12px 30px;
      height: 152px;
    }

    .a-decadeCardGradient {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      //background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(55,55,55,0.6) 33%, rgba(55,55,55,0) 100%);
      background: radial-gradient(circle at 100%, rgba(55,55,55,0), rgba(55,55,55,0.1) 60%, rgba(55,55,55,0.3) 75%, rgba(55,55,55,1) 95%, rgba(55,55,55,1) 100%);
    }
  }
}
</style>
