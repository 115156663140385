<template>
  <router-link
      class="m-btnArrow"
      v-bind:class="{ '-left' : direction , '-right' : !direction }"
      :to = "content.code"
  >
    <span class="a-btnArrowIcon" v-if="direction">
      <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.20288 18.4056L0 9.20281L9.20288 0L10.7496 1.54684L2.90625 9.20276L10.7496 16.8588L9.20288 18.4056Z" fill="black"></path>
      </svg>
    </span>
    <span class="a-btnArrowIcon" v-else>
      <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.70337 18.7029L10.9062 9.50005L1.70337 0.297241L0.156603 1.84408L8 9.5L0.156603 17.156L1.70337 18.7029Z" fill="black"></path>
      </svg>
    </span>
    <span class="a-btnArrowName">
      <span>{{content.title.substr(0,4)}}</span>
      <span>{{content.title.substr(5,4)}}</span>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'BtnArrow',
  props: {
    direction: Boolean,
    content: Object
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.m-btnArrow {
  display: flex;
  align-items: center;
  max-width: 80px;
  color: $black;
  position: fixed;
  top: 50%;

  @include mediaMaxW($xs) {
    display: none;
  }

  .a-btnArrowIcon {
    height: 30px;

    svg {
      height: 100%;
      width: auto;
    }
  }

  .a-btnArrowName {
    font-size: 12px;
    text-align: center;

    span {
      display: block;
      &:first-child {
        border-bottom: 1px solid $black;
      }
    }
  }

  &.-left {
    justify-content: flex-start;
    flex-direction: row;
    left: 48px;

    .a-btnArrowIcon {
      margin-right: 6px;
    }
  }
  &.-right {
    justify-content: flex-end;
    flex-direction: row-reverse;
    right: 48px;

    .a-btnArrowIcon {
      margin-left: 6px;
    }
  }

  &:hover,
  &:active {
    .a-btnArrowIcon svg path {
      fill: $accentGreen;
    }
  }
}
</style>
