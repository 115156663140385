<template>
  <main class="pagePlay">
    <video
        class="a-video full-video"
        src="https://hhc.kubsau.ru/upload/iblock/457/kouh4tk1oabibrbn00mwwk8t7hy223qa/КУБГАУ ФИНАЛ_2.mp4"
        preload="metadata"
        controls
        ref="video"

    />
  </main>
</template>

<script>
import {mapMutations} from "vuex";
import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
Vue.use(new VueSocketIO({
      debug: true,
      connection: SocketIO('https://museum.a-dev.xyz:7860'),
    })
);

export default {
  name: 'PlayPage',
  metaInfo: {
    title: 'Видео',
  },
  methods: {
    ...mapMutations (["setNotStartPage"]),
  },
  beforeMount() {
    this.setNotStartPage(false)
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    message:function(data){
      if(data && data.event == 'play')
        this.$refs.video.play();
    },
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.pagePlay {
  background: #000000;
  display: flex;
  justify-content: center;
}
.full-video {
  height: 100vh;
}
</style>
