<template>
  <div class="m-container">
    <div class="m-text">

      <img v-if=links.picture v-img:group :src=links.picture alt="Академик Трубилин Иван Тимофеевич">

      <div v-if=links.content v-html="links.content"></div>

      <div v-if="links.photos" class="m-text">
        <img
            :key="item.item"
            v-for="item in links.photos"
            v-img:group :src=item alt="Академик Трубилин Иван Тимофеевич">
      </div>

    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {Trubilin} from "@/models/models";
import axios from "axios";

export default {
  name: 'TrubilinPage',
  metaInfo: {
    title: 'Трубилин Иван Тимофеевич',
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Trubilin
  },
  beforeMount() {
    this.setTitle("<span>Академик</span><br>Трубилин<br>Иван Тимофеевич")
    this.setPageClass("pageTrubilin")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null,
    };
  },
  mounted() {
    axios
        .get('/api/page/trubilin')
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">

</style>
