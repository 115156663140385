<template>
  <modal name="QrLink">
    <h2>
      Откройте сайт с&nbsp;телефона
    </h2>
    <img src="../assets/img/qrHeritage.gif">
  </modal>
</template>

<script>

export default {
  name: 'ModalDialog',
  methods: {
    show () {
      this.$modal.show('QrLink');
    },
    hide () {
      this.$modal.hide('QrLink');
    }
  }
}
</script>


<style lang="scss">
.vm--modal {
  height: auto!important;
  padding: 36px 24px 24px 24px ;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 400px!important;
  top: 50%!important;
  left: 50%!important;
  transform: translate(-50%, -50%);

  img {
    max-height: 350px;
    max-width: 350px;
  }
  h2 {
    font-size: 24px;
    text-align: center;
  }
}
</style>
