<template>
  <div id="app">
    <TheHeader v-if="getNotStartPage" />
    <main  v-if="getNotStartPage"  class="o-page" v-bind:class='{ [getPageClass] : getPageClass }'>
      <div class="o-title">
        <h1 class="h1" v-html="getTitle"></h1>
      </div>
      <div class="o-content" v-on:scroll="showGoToTopBtn()">
        <div class="a-contentGradient -top"></div>
        <router-view :key="$route.fullPath"></router-view>
          <div class="a-contentGradient -bottom"></div>
        </div>
      <TheFooter />
    </main>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/layouts/TheHeader";
import TheFooter from "@/layouts/TheFooter";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  metaInfo: {
    title: 'Основное меню',
    titleTemplate: '%s | Центр исторического насления КубГАУ',
  },
  components: {
    TheHeader,
    TheFooter
  },
  computed: {
    ...mapGetters(["getTitle"]),
    ...mapGetters(["getPageClass"]),
    ...mapGetters(["getNotStartPage"]),
  },
  methods: {
    showGoToTopBtn() {
      let scrollHeight = document.querySelector('.o-content').scrollTop;
      let buttonToTop = document.querySelector('.goToTop');

      if (scrollHeight > "120") {
        buttonToTop.style.display = "flex";
      } else {
        buttonToTop.style.display = "none";
      }
    }
  }
}

</script>

<style lang="scss">
@import "./assets/css/main.scss";
@import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
</style>
