<template>
  <div>
    <h2 class="h2">{{title}}</h2>
    <div v-if="youtube" class="m-video">
      <iframe
          :src="'https://www.youtube.com/embed/'+url"
          modestbranding="0"
          allowfullscreen=""
      >
      </iframe>
    </div>
    <div v-else class="m-video">
      <video
          class="a-video"
          :poster="poster"
          :src="url"
          preload="metadata"
      >
      </video>
      <BtnPlay
          @click="playVideo()"
      />
    </div>
  </div>
</template>

<script>
import BtnPlay from "@/components/BtnPlay";

export default {
  name: 'CardVideo',
  components: {BtnPlay},
  props: {
    title: String,
    poster: String,
    url: String,
    youtube: Boolean
  },
  methods: {
    playVideo: function () {

      let video = this.$el.lastChild.firstChild
      let btn = this.$el.lastChild.lastChild

      btn.remove()
      video.setAttribute("controls", "controls")
      video.play()

    }
  }
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.m-video {
  width: 100%;
  position: relative;

  video,
  iframe {
    width: 100%;
  }

  iframe {
    height: 366px;
    @include mediaMaxW($xs) {
      height: 195px;
    }
  }
}
</style>
