<template>
  <router-link
      class="a-btnNav"
      v-bind:class="{ '-small' : small }"
      :to="link"
  >
    <span class="a-btnNavAccent"></span>
    <span class="a-btnNavBody" v-html="name"></span>
  </router-link>
</template>

<script>
export default {
  name: 'BtnNav',
  props: {
    name: String,
    link: String,
    small: Boolean
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.a-btnNav {
  display: flex;
  padding: 24px 48px 24px 60px;
  min-height: 170px;
  align-items: center;
  border-radius: 4px;
  position: relative;
  background: $black5;

  @include mediaMinW($md) {
    min-height: 100px;
  }
  @include mediaMaxW($xs) {
    min-height: 68px;
    padding: 12px 24px 12px 40px;
  }
  @include mediaMaxW($xsMobile) {
    min-height: 42px;
    padding: 12px 12px 12px 40px;
  }

  & + & {
    margin-top: 40px;

    @include mediaMinW($md) {
      margin-top: 20px;
    }
    @include mediaMaxW($xs) {
      margin-top: 12px;
    }
  }

  &Body {
    font-size: 44px;
    color: $mainGrey;
    line-height: 46px;

    @include mediaMinW($md) {
      font-size: 30px;
      line-height: 30px;
    }

    @include mediaMaxW($xs) {
      font-size: 24px;
      line-height: 24px;
    }

    @include mediaMaxW($xsMobile) {
      font-size: 18px;
      line-height: 18px;
    }
    span {
      font-size: 0.8em;
    }
  }

  &Accent {
    height: 100%;
    width: 36px;
    display: block;
    position: absolute;
    left: 0;
    background: linear-gradient(300deg, rgba(122,213,40,1) 0%, rgba(119,199,37,1) 33%, rgba(59,176,30,1) 66%, #3aaf1e 100%);

    @include mediaMaxW($xs) {
      width: 18px;
    }
  }

  &:hover,
  &:active,
  &.active {
    background: $accentGreen;
    background: linear-gradient(300deg, rgba(122,213,40,1) 0%, rgba(119,199,37,1) 33%, rgba(59,176,30,1) 66%, #3aaf1e 100%);

    .a-btnNavBody {
      color: $white;
    }
    .a-btnNavAccent {
      background: $mainGrey;
    }
  }

  &.-small {
    min-height: 120px;

    @include mediaMaxW($xs) {
      min-height: 64px;
    }
  }
}

</style>
