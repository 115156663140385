<template>
  <div class="o-header">
    <div class="m-wrapperLogo">
      <div class="a-logo" href="main.html"><img class="a-logoImg" src="../assets/img/logo100White.svg"></div>
      <div class="a-logoCaption">Центр исторического<br>наследия КубГАУ</div>
      <button class="a-btnHamburger" data-action="menu" v-on:click="menuVisible=!menuVisible">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5H25" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path>
          <path d="M1 10H25" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path>
          <path d="M1 15H25" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path>
        </svg>
      </button>
    </div>
    <transition name="fade">
      <div class="o-mobileMenu" v-show="menuVisible">
        <div class="m-mobileMenuTop">
          <button class="a-mobileMenuClose" data-action="menu" v-on:click="menuVisible=!menuVisible">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="-1" x2="27.619" y2="-1" transform="matrix(0.689655 0.724138 -0.689655 0.724138 1 2)"
                    stroke="#3aaf1e" stroke-width="2">
              </line>
              <line y1="-1" x2="27.619" y2="-1" transform="matrix(-0.689655 0.724138 0.689655 0.724138 21 2)"
                    stroke="#3aaf1e" stroke-width="2">
              </line>
            </svg>
          </button>
        </div>
        <div class="m-mobileMenuCenter">
          <BtnNav
              :key="link.link"
              v-for="link in links"
                :name="link.name"
                :link="link.link"
          />
        </div>
        <div class="m-mobileMenuBottom">
          <a class="a-mobileMenuBottom-item" href="tel:+78612564532">+7(861) 256-45-32</a>
          <a class="a-mobileMenuBottom-item" href="mailto:mail@kubsau.ru">mail@kubsau.ru</a>
          <a class="a-mobileMenuBottom-item" href="https://kubsau.ru">kubsau.ru</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BtnNav from "@/components/BtnNav";

export default {
  name: 'TheHeader',
  components: {
    BtnNav
  },
  watch:{
    $route (){
      this.menuVisible = false;
    }
  },
  data() {
    return {
      menuVisible: false,
      links: [
        { name: "Основное меню", link: "/"},
        { name: "Десятилетия", link: "/decades"},
        { name: "Видеогалерея", link: "/video"},
        { name: "<span>Академик</span> Трубилин&nbsp;Иван&nbsp;Тимофеевич", link: "/trubilin"},
        { name: "Факультеты", link: "/faculties"},
        { name: "Подразделения университета", link: "/departments"},
        { name: "Книги о КубГАУ", link: "/books"},
      ],
    }
  }
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.o-header {
  .m-wrapperLogo {
    background: url(../assets/img/bgLogo.png) top left no-repeat;
    background-size: contain;
    width: 178px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 24px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    @include mediaMaxW($xs) {
      background: linear-gradient(270deg, #3bb01e 60%, $mainGrey);
      width: 100%;
      height: 40px;
      flex-direction: row;
      padding-top: 0;
    }

    .a-logoImg {
      height: 70px;

      @include mediaMaxW($xs) {
        height: 20px;
        margin-left: 12px;
        margin-right: 8px;
      }
    }
    .a-logoCaption {
      color: $white;
      text-align: center;
      font-size: 10px;
      padding: 10px 20px;

      @include mediaMaxW($xs) {
        text-align: left;
        font-size: 8px;
        padding: 10px;
        width: 80%;
      }
    }
    .a-linkInstagram {
      display: none;

      @include mediaMaxW($xs) {
        display: flex;
        padding: 12px 6px;
      }
    }
  }
  .a-btnHamburger {
    display: none;

    @include mediaMaxW($xs) {
      display: block;
      padding: 12px;
    }
  }
}
.o-mobileMenu {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: #FFFFFF;
  flex-direction: column;
  justify-content: flex-start;

  &.open {
    display: flex;
  }

  .m-mobileMenuTop {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;

    .a-mobileMenuClose {
      padding: 12px;
    }
  }
  .m-mobileMenuCenter {
    padding: 0 24px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;

    .a-btnNav {
      min-height: 42px;
      padding: 8px 12px 8px 22px;

      .a-btnNavAccent {
        width: 10px;
      }

      .a-btnNavBody {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
  .m-mobileMenuBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;

    .a-mobileMenuBottom-item {
      display: block;
      padding: 4px;
      margin: 2px;
      color: $accentGreen;
      font-weight: 700;
      font-size: 18px;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
