<template>
  <div class="m-container">
    <div class="m-text">
      <BtnArrow
          :key="links"
          :direction=true
          :content=links.previous
      />
      <BtnArrow
          :key="links"
          :direction=false
          :content=links.next
      />
      <img v-if=links.picture v-img:group :src=links.picture :alt=links.name>
      <div v-if=links.content v-html="links.content"></div>
      <div v-if="links.pictures" class="m-text">
        <img
            :key="item.item"
            v-for="item in links.pictures"
            v-img:group :src=item :alt=links.name>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import BtnArrow from "@/components/BtnArrow";
import {Decade} from "@/models/models";
import axios from "axios";

export default {
  name: 'DecadePage',
  metaInfo: {
    title: "1922-1931",
  },
  components: {BtnArrow},
  methods: {
    ...mapMutations(["setTitle", "setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Decade
  },
  beforeMount() {
    this.setTitle(this.$route.params.code)
    this.setPageClass("pageDecade")
    this.setBtnHome(true)
    this.setBtnBack(true)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null,
    };
  },
  mounted() {
    axios
        .get('/api/decades/'+this.$route.params.code)
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">

</style>
