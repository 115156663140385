<template>
  <div class="m-container">
    <BtnNav
        :key="link.link"
        v-for="link in links"
          :name="link.name"
          :link="link.link"
    />
  </div>
</template>

<script>
import BtnNav from "../components/BtnNav";
import {mapMutations} from "vuex";

export default {
  name: 'MainPage',
  components: {
    BtnNav
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  beforeMount() {
    this.setTitle("Основное меню")
    this.setPageClass("pageMain")
    this.setNotStartPage(true)
    this.setBtnHome(false)
    this.setBtnBack(false)
  },
  data() {
    return {
      links: [
        { name: "Десятилетия", link: "decades"},
        { name: "Видеогалерея", link: "video"},
        { name: "<span>Академик</span> Трубилин&nbsp;Иван&nbsp;Тимофеевич", link: "trubilin"},
        { name: "Факультеты", link: "faculties"},
        { name: "Подразделения университета", link: "departments"},
        { name: "Книги о КубГАУ", link: "books"},
      ],
    };
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageMain {
  .m-container {
    @include mediaMinW($md) {
      max-width: 640px;
    }
  }
  @include mediaMaxW($xs) {
    justify-content: flex-start;
  }
}
</style>
