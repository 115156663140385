import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './routes'
import Vuex from 'vuex'
import VueImg from 'v-img';
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(Vuex)
Vue.use(VueImg)
Vue.use(VModal)
const store = new Vuex.Store({
    state: {
        title: 'Основное меню',
        pageClass: 'pageMain',
        btnHome: false,
        btnBack: false,
        notStartPage: true,
    },
    mutations: {
        setTitle (state, title) {
            state.title = title
        },
        setPageClass (state, pageClass) {
            state.pageClass = pageClass
        },
        setBtnHome (state, btnHome) {
            state.btnHome = btnHome
        },
        setBtnBack (state, btnBack) {
            state.btnBack = btnBack
        },
        setNotStartPage (state, notStartPage) {
            state.notStartPage = notStartPage
        },
    },
    getters: {
        getTitle (state) {
          return state.title
        },
        getPageClass (state) {
          return state.pageClass
        },
        getBtnHome (state) {
            return state.btnHome
        },
        getBtnBack (state) {
            return state.btnBack
        },
        getNotStartPage (state) {
            return state.notStartPage
        }
    },
    actions: {
    }
})

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
})

