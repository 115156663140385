<template>
  <div class="accordion-container">
    <div class="ac" v-bind:key="item" v-for="item in list">
      <div class="ac-header">{{item.name}}<span class="ac-icon"></span></div>
      <div class="ac-panel">
        <div class="ac-text">
          <img v-if="item.picture" :src="item.picture" :alt="item.name">
          <div v-if="item.text" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlockAccordion",
  props: {
    list: {
      id: Number,
      name: String,
      text: String,
      picture: String
    }
  },
  mounted () {

    let acc = document.getElementsByClassName("ac-header");
    let i;

    for(i=0; i<acc.length; i++) {

      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        let panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }

  }
}
</script>

<style lang="scss">
@import "../assets/css/main";
.accordion-container {

  .ac {
    margin-top: 0px;
    border: none;

    .ac-header {
      border-left: 4px solid $accentGreen;
      font-size: 22px;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 700;
      width: 100%;
      padding: 10px 60px 10px 10px;
      position: relative;

      .ac-icon {
        display: flex;
        width: 12px;
        position: absolute;
        right: 12px;
        top: 50%;
        bottom: 0;
        transform: translate(0, -50%);


        &:after {
          content: '\002B';
          color: #000;
          font-size: 22px;
          font-weight: 700;
          text-align: center;
          width: 15px;
          transform: translate(0, -50%);
          position: absolute;
          right: 10px;
          top: 50%;
        }
      }


      @include mediaMaxW($xs) {
        font-size: 16px;
      }

      &:active,
      &:hover {
        background: $lightGreen;
        transition: 0.3s;
      }

      &.active {
        background: $accentGreen;
        color: $white;

        .ac-icon {

          &:after {
            content: "\2212";
            color: $white;
          }
        }
      }
    }

    .ac-panel {
      border-left: 4px solid $black20;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;

      .ac-text {
        padding: 10px 24px;

        @include mediaMaxW($xs) {
          padding: 6px 16px;
        }

        img {
          max-width: 90%;
        }

        p {
          font-family: $mainFont;
          font-size: 18px;
          line-height: 24px;
          text-indent: 0;
          margin: 8px 0;
          text-align: left;

          @include mediaMaxW($xs) {
            font-size: 14px;
            line-height: 18px;
            margin: 4px 0;
          }
        }
      }
    }
  }

  .ac + .ac {
    margin-top: 8px;
  }
}
</style>