<template>
  <div class="m-container">
    <BtnNav
        :key="link.link"
        v-for="link in links"
          :name="link.title"
          :link="'departments/'+link.code"
          :small=true
    />
  </div>
</template>

<script>
import BtnNav from "../components/BtnNav";
import {mapMutations} from "vuex";
import axios from "axios";
import {Departments} from "@/models/models";

export default {
  name: "DepartmentsPage",
  metaInfo: {
    title: 'Подразделения',
  },
  components: {
    BtnNav
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  item:{
    type: Departments
  },
  beforeMount() {
    this.setTitle("Подразделения университета")
    this.setPageClass("pageDepartments")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  },
  data() {
    return {
      links: null
    };
  },
  mounted() {
    axios
        .get('/api/departments')
        .then(response => (this.links = response.data))
        .catch(() => this.$router.push({name: "NotFound"}));
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageDepartments {
  .m-container {
    @include mediaMinW($md) {
      max-width: 640px;
    }
  }
}
</style>
