<template>
  <button class="a-btnPlay" @click="$emit('click')">
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.19583 6.51285L1.67572 0.536081C1.03432 -0.0518704 0 0.403131 0 1.27324V13.2268C0 14.0969 1.03432 14.5519 1.67573 13.9639L8.19583 7.98715C8.6282 7.59082 8.6282 6.90918 8.19583 6.51285Z" fill="#666666"></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'BtnPlay',
  props: {
    name: String,
    link: String,
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.a-btnPlay {
  display: flex;
  border-radius: 10px;
  border: 1px solid $accentGreen;
  background: $lightGreen;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #22222299;
  font-weight: 700;
  width: 42px;
  height: 42px;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  svg {
    width: 24px;
    height: 24px;
    margin-left: 4px;

    path {
      fill: $accentGreen;
    }
  }

  &:hover, &:active {
    border: 1px solid $accentGreen;
    box-shadow: $shadowGreen;
    transition: 0.3s;
  }

}
</style>
