<template>
  <div class="m-container">

      <h1 class="h1">Страница не найдена</h1>
      <div class="error-404">
        <div class="digit">404</div>
        <div class="text">Error: page not found</div>
        <BtnSquare
           icon="home"
           title="Вернустья в основное меню"
           text="Перейдите на главную страницу"
           link="/"
           green
        />
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import BtnSquare from "@/components/BtnSquare";

export default {
  name: "NotFoundPage",
  metaInfo: {
    title: 'Ошибка 404',
  },
  components: {
    BtnSquare
  },
  methods: {
    ...mapMutations (["setTitle","setPageClass", "setBtnHome", "setBtnBack","setNotStartPage"])
  },
  beforeMount() {
    this.setTitle("")
    this.setPageClass("pageNotFound")
    this.setBtnHome(true)
    this.setBtnBack(false)
    this.setNotStartPage(true)
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/main";
.pageNotFound{
  .h1 {
    text-align: center;
  }
  .error-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .digit {
      color: $red;
      font-weight: 700;
      font-size: 120px;
      line-height: 120px;
      margin-top: 36px;
    }
    .text {
      color: $red;
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 56px;
    }

    .a-btnSquare {
      height: 50px;
      width: 300px;
    }
  }
}
</style>
