<template>
  <div>
    <BtnSquare
        icon="up"
        title="Вернуться наверх"
        link="javascript:void(0)"
        style="display:none;"
        green
        goToTop
        @click="scrollToTop()"
    />
    <div class="o-footer">
      <div class="m-footerTop">
        <div class="m-footerTop-left">
          <BtnSquare v-if="getBtnBack"
             icon="back"
             title="Вернустья назад"
             link="javascript:void(0)"
             green
             @click="goBack()"
          />
          <BtnSquare v-if="getBtnHome"
              icon="home"
              title="Вернустья в основное меню"
              link="javascript:void(0)"
              @click="goHome()"
          />
        </div>
        <div class="m-footerTop-center">
          <BtnSquare
              action="#link"
              icon="link"
              title="Откройте сайт с телефона"
              link="javascript:void(0)"
              @click="showQR()"
          />
        </div>
        <div class="m-footerTop-right">
        </div>
      </div>
      <div class="m-footerBottom">
        <div class="m-footerBottom-left">
          <div class="a-footerBottom-item">
            &copy; Кубанский ГАУ, 2022.
          </div>
        </div>
        <div class="m-footerBottom-right">
          <div class="a-footerBottom-item">
            +7(861) 256-45-32
          </div>
          <div class="a-footerBottom-item">
            mail@kubsau.ru
          </div>
          <div class="a-footerBottom-item">
            kubsau.ru
          </div>
        </div>
      </div>
    </div>
    <modal class="m-popup" id="link" style="display:none;max-width:500px;">
      <h2>
        Откройте сайт с&nbsp;телефона
      </h2>
      <img src="../assets/img/qrHeritage.gif">
    </modal>
    <ModalDialog></ModalDialog>
  </div>
</template>

<script>
import BtnSquare from "@/components/BtnSquare";
import ModalDialog from "@/components/ModalDialog";
import {mapGetters} from "vuex";

export default {
  name: "TheFooter",
  components: {
    BtnSquare,
    ModalDialog
  },
  computed: {
    ...mapGetters(["getBtnHome"]),
    ...mapGetters(["getBtnBack"]),
  },
  methods: {
    scrollToTop: function () {
      let element = document.getElementsByClassName("m-container");
      if(element && element.length>0) {
        element[0].scrollIntoView({block: "start", behavior: "smooth"});
      }
    },
    showQR: function () {
      this.$modal.show("QrLink")
    },
    goHome: function () {
      this.$router.push('/')
    },
    goBack: function () {
      this.$router.push(this.$route.meta.parent)
    }
  }
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";
.o-footer{
  width: 100%;
  position: relative;

  @include mediaMaxW($xs) {
    display: none;
  }

  .m-footerTop {
    background: $white;
    height: 90px;
    background: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @include mediaMinW($md) {
      height: 48px;
    }
    @include mediaMaxW($xs) {
      height: 40px;
      padding: 0 12px;
    }

    &-center,
    &-left,
    &-right {
      display: flex;

      .a-btnSquare + .a-btnSquare{
        margin-left: 12px;
      }
    }

    &-left,
    &-right {
      min-width: 20%;
    }
    &-left {
      justify-content: flex-start;
    }
    &-right {
      justify-content: flex-end;
    }
  }

  .m-footerBottom {
    min-height: 40px;
    background: $mainGrey;
    color: $white40;
    border-top: 2px solid $accentGreen;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;

    @include mediaMaxW($xs) {
      font-size: 10px;
      line-height: 10px;
      padding: 0 12px;
    }

    &-left,
    &-right{
      display: flex;
      align-items: center;
    }
    &-left {
      justify-content: flex-start;
    }
    &-right{
      justify-content: flex-end;
    }
    .a-footerBottom-item + .a-footerBottom-item {
      padding-left: 16px;
    }
  }
}
.m-popup {
  display: none;
  max-width:600px;

  h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 24px;
  }
}
.fancybox-slide--html .fancybox-close-small {
  height: 64px;
  width: 64px;
}

</style>
