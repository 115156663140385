<template>
  <div class="m-book">
    <div class="m-bookCover">
      <img v-if=cover
          :src=cover
          :alt=title
      >
    </div>
    <div class="m-bookDescription">
      <div v-if=title
          class="a-bookTitle"
          v-html="title"
      >
      </div>
      <div
          class="a-bookAuthors"
          v-if="authors"
          v-html="authors"
      >
      </div>
      <div
          class="a-bookAnnotation"
          v-if="description"
          v-html="description"
      >
      </div>
      <div class="m-bookQrCode" v-if="qr">
        <div>Скачать на телефон:</div>
        <img
          :src=qr
        >
      </div>
      <a v-if="link" class="a-booklink" :href="link">Читать книгу</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardBook',
  props: {
    cover: String,
    title: String,
    authors: String,
    description: String,
    qr: String,
    link: String
  },
}
</script>


<style scoped lang="scss">
@import "../assets/css/main";

.m-book {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 82px;
  padding-bottom: 82px;
  border-top: 1px solid $black10;

  @include mediaMaxW($xs) {
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  .m-bookCover {
    width: 40%;
    @include mediaMaxW($xs) {
      width: 60%;
    }

    img {
      width: 100%;
      height: auto;
      box-shadow: $shadowSmall;
    }
  }

  .m-bookDescription {
    width: 55%;

    @include mediaMaxW($xs) {
      width: 100%;
      padding-top: 16px;
    }

    .a-bookTitle {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 12px;
      line-height: 28px;

      @include mediaMaxW($xs) {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .a-bookAuthors {
      font-style: italic;
    }

    .a-bookAnnotation {
      margin-top: 18px;
      font-size: 12px;
      line-height: 16px;
    }

    .m-bookQrCode {
      margin-top: 12px;
      font-weight: 700;

      @include mediaMaxW($xs) {
        display: none;
      }

      img {
        display: flex;
        max-width: 220px;
        border: 1px solid $black10;
        margin-top: 6px;
        border-radius: 4px;
      }
    }

    .a-booklink {
      display: none;

      @include mediaMaxW($xs) {
        margin-top: 8px;
        display: block;
        font-weight: 700;
        color: $mainGreen;
        text-decoration: underline;
      }
    }
  }
}

</style>
