export class Video {
    id;
    title;
    type;
    content;
    constructor({id,title,type,content}) {
        this.id=id;
        this.title=title;
        this.type=type;
        this.content=content;
    }
}
export class Decades {
    id;
    title;
    preview;
    code;
    constructor({id,title,preview,code}) {
        this.id=id;
        this.title=title;
        this.preview=preview;
        this.code=code;
    }
}
export class Decade {
    id;
    name;
    content;
    picture;
    previous;
    next;
    seo;
    pictures;
    constructor({id,name,content,picture,previous,next,seo,pictures}) {
        this.id=id;
        this.name=name;
        this.content=content;
        this.picture=picture;
        this.previous=previous;
        this.next=next;
        this.seo=seo;
        this.pictures=pictures;
    }
}
export class Books {
    id;
    title;
    preview;
    code;
    authors;
    link;
    qr;
    constructor({id,title,preview,code,authors,link,qr}) {
        this.id=id;
        this.title=title;
        this.preview=preview;
        this.code=code;
        this.code=authors;
        this.code=link;
        this.code=qr;
    }
}
export class Trubilin {
    id;
    name;
    content;
    picture;
    photos;
    constructor({id,name,content,picture,photos}) {
        this.id=id;
        this.title=name;
        this.preview=content;
        this.code=picture;
        this.code=photos;
    }
}
export class Faculties {
    id;
    title;
    code;
    constructor({id,title,code}) {
        this.id=id;
        this.title=title;
        this.code=code;
    }
}
export class Departments {
    id;
    title;
    code;
    constructor({id,title,code}) {
        this.id=id;
        this.title=title;
        this.code=code;
    }
}
export class Faculty {
    id;
    name;
    content;
    picture;
    photos;
    departments;
    seo;
    constructor({id,name,content,picture,photos,departments,seo}) {
        this.id=id;
        this.name=name;
        this.content=content;
        this.picture=picture;
        this.previous=photos;
        this.next=departments;
        this.seo=seo;
    }
}
export class Department {
    id;
    name;
    content;
    picture;
    photos;
    departments;
    seo;
    constructor({id,name,content,picture,photos,departments,seo}) {
        this.id=id;
        this.name=name;
        this.content=content;
        this.picture=picture;
        this.previous=photos;
        this.next=departments;
        this.seo=seo;
    }
}